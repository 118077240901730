import xs, { Stream } from 'xstream';
import delay from 'xstream/extra/delay';
import { div, button, p, makeDOMDriver, form, input, a, br, img, span } from '@cycle/dom';
import { run } from '@cycle/run';
import { JSEncrypt } from "jsencrypt";
import $ from "jquery";
function encrypt(encryptString) {
    var public_key = '-----BEGIN PUBLIC KEY-----\
  MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAyaQn+Umg2iovdh2h+5wD\
  MvAleOmPRgIfV9qi6tteRb26dlYi2VBaRDIb7B7elYhA5tq+gvLGEAumwRVzwklt\
  ZjXBIgDO3reMEePk07SvLdVeOJZ3/qJvZitB0AR5ezdmUJfFCO0QsqBeZwD7NCnE\
  yyLfV2syU71ma2VRtL1lC12ZH4NpG2+GZEZ5MDA45QTlT3/LRxuNWqf44Mme3UjG\
  FKL11MlJw8cz1clsOubvO5bmPqSTXdevpgcun+CQ4w/S5ciqefNfPYEX/cVND3Sk\
  89RiNUPN10wk4Cm1FmYAYBrwUW1zFZObdWy47AZHB+GYPZURudqEJDKJBs9lkHkt\
  gSK65Vxqv9u9Mzjrz9fsWTmK1vPY6KxJwqFtLur7tjP3jDeEgTq1fwDAp7+mhgnS\
  QjQCdp1p8zMM849OWpK6hR4rQpGltn4KWDihnpeiZn2lgh7FoDemM1Ru/3bbmW/7\
  m/76uJVX5bJjCpraVbHp138FjNT0a7s9TgP2ptNa40ikjqMLIFbgKfc8xOETyhEk\
  QjWlcKtT86lZVvRIg9rLn1hHHgnJM0ufHoQWO1jr/2Pjuz4a1iX8iD3mFdcLK72M\
  bsGfNUxL63eMDHsdoGhCYm23KDIzIeCqWbOV/0Iu04JQgbKNbYUoNcvUiqipnedo\
  Wunek4Hht8gDcC6Cvz5QSa0CAwEAAQ==\
  -----END PUBLIC KEY-----';
    var encrypt = new JSEncrypt();
    encrypt.setPublicKey(public_key);
    var encrypted = encrypt.encrypt(encryptString);
    // console.log(encrypted);
    return encrypted;
}
var Timestamp = /** @class */ (function () {
    function Timestamp() {
    }
    return Timestamp;
}());
var StartAction = /** @class */ (function () {
    function StartAction() {
        Timestamp.timestamp = Date.now();
    }
    return StartAction;
}());
var LoginAction = /** @class */ (function () {
    function LoginAction(what) {
        this.what = what;
    }
    LoginAction.prototype.getWhat = function () {
        return this.what;
    };
    LoginAction.prototype.setWhat = function (what) {
        this.what = what;
    };
    return LoginAction;
}());
var RegisterAction = /** @class */ (function () {
    function RegisterAction(what) {
        this.what = what;
    }
    RegisterAction.prototype.getWhat = function () {
        return this.what;
    };
    RegisterAction.prototype.setWhat = function (what) {
        this.what = what;
    };
    return RegisterAction;
}());
var OverlayAction = /** @class */ (function () {
    function OverlayAction(what) {
        this.what = what;
    }
    OverlayAction.prototype.getWhat = function () {
        return this.what;
    };
    OverlayAction.prototype.setWhat = function (what) {
        this.what = what;
    };
    return OverlayAction;
}());
var ResponseAction = /** @class */ (function () {
    function ResponseAction(what, loginRequest) {
        this.what = what;
        this.loginRequest = loginRequest;
    }
    ResponseAction.prototype.getWhat = function () {
        return this.what;
    };
    ResponseAction.prototype.setWhat = function (what) {
        this.what = what;
    };
    ResponseAction.prototype.getLoginRequest = function () {
        return this.loginRequest;
    };
    return ResponseAction;
}());
var DoNothingAction = /** @class */ (function () {
    function DoNothingAction() {
    }
    return DoNothingAction;
}());
var PageState = /** @class */ (function () {
    function PageState(state) {
        this.stateOverlay = state;
        this.stateLoginForm = '';
    }
    PageState.prototype.setStateOverlay = function (state) {
        this.stateOverlay = state;
        if (state === '.visible') {
            document.body.classList.add('overlayNoScroll');
        }
        else if (state === '.hidden') {
            document.body.classList.remove('overlayNoScroll');
        }
    };
    PageState.prototype.getStateOverlay = function () {
        return this.stateOverlay;
    };
    PageState.prototype.setStateLoginForm = function (state) {
        this.stateLoginForm = state;
    };
    PageState.prototype.getStateLoginForm = function () {
        return this.stateLoginForm;
    };
    PageState.prototype.setStateRegister = function (state) {
        this.stateRegister = state;
    };
    PageState.prototype.getStateRegister = function () {
        return this.stateRegister;
    };
    PageState.prototype.setStateLogin = function (response) {
        this.stateLogin = response;
    };
    PageState.prototype.getStateLogin = function () {
        return this.stateLogin;
    };
    return PageState;
}());
function postForm(url, loginRequest) {
    var form = document.createElement('form');
    form.action = url;
    form.method = 'POST';
    var input = document.createElement('input');
    input.type = 'hidden';
    input.name = 'username';
    input.value = loginRequest.getUser();
    form.appendChild(input);
    var input = document.createElement('input');
    input.type = 'hidden';
    input.name = 'password';
    input.value = loginRequest.getPass();
    form.appendChild(input);
    document.body.appendChild(form);
    form.submit();
}
function update(state, action) {
    if (action instanceof OverlayAction) {
        var a_1 = action;
        state.setStateOverlay(a_1.getWhat());
        state.setStateLogin(undefined);
        state.setStateLoginForm(undefined);
    }
    else if (action instanceof StartAction) {
        hideLoading();
        state.setStateLogin(undefined);
    }
    else if (action instanceof LoginAction) {
        var a_2 = action;
        state.setStateLoginForm(a_2.getWhat());
        state.setStateLogin(undefined);
    }
    else if (action instanceof ResponseAction) {
        var a_3 = action;
        if (a_3.getWhat().response === 'denied') {
            count += 1;
            if (count > 5) {
                state.setStateLogin(undefined);
                state.setStateLoginForm('.return');
                count = 0;
            }
            else {
                state.setStateLogin(false);
                hideLoading();
                setTimeout(function () {
                    if (state.getStateLoginForm() === 'boerseForm') {
                        $('.usernamebde').focus();
                        $('.usernamebde').select();
                    }
                    else if (state.getStateLoginForm() === 'icForm') {
                        $('.usernameic').focus();
                        $('.usernameic').select();
                    }
                    else {
                        $('.usernamebv').focus();
                        $('.usernamebv').select();
                    }
                }, 100);
            }
        }
        else if (a_3.getWhat().response === 'accept') {
            hideLoading();
            state.setStateLoginForm('accept');
            setTimeout(function () { postForm('https://www.boerse.de/ajax/auth.php', a_3.getLoginRequest()); }, 1300);
        }
        else if (a_3.getWhat().response === 'ic_redirect') {
            var hash_1 = a_3.getWhat().hash;
            hideLoading();
            state.setStateLoginForm('accept');
            setTimeout(function () { window.location.replace("https://www.boerse-investoren-club.de/login/?hash=" + hash_1); }, 1300);
        }
        else {
            hideLoading();
            state.setStateLoginForm('accept');
            setTimeout(function () { window.location.replace("https://www.boersenverlag.de/login/?hash=" + a_3.getWhat().response); }, 1300);
        }
        // state.setStateLoginBoerse(LoginUrl.createOrBoolean(a.getWhat()));
    }
    else if (action instanceof RegisterAction) {
        var a_4 = action;
        state.setStateRegister(a_4.getWhat());
    }
    else if (action instanceof LoginRequest) {
        var a_5 = action;
    }
    else if (action instanceof DoNothingAction) {
        state.setStateLogin(undefined);
    }
    return state;
}
function toView(state) {
    if (state.getStateLogin() === false) {
        var deniedMessage = div([p('.loginFailed', 'Username oder Passwort sind nicht korrekt.')]);
    }
    else {
        var deniedMessage = div([p('.loginFailed')]);
    }
    // Login-BV
    var view2 = div('.row.overlayWrapper', [
        div('.formular.col-md-12', [
            div('.loginBoerseFormular', [
                div('.row', [
                    div('.col-md-3 col-sm-3 zurueck', [
                        button('.return bvButton', 'zurück'),
                    ]),
                    div('.col-md-6 col-sm-6', [
                        img('.mybacLogo', { attrs: { src: 'https://www.boerse.de/fileadmin/img/Logos/tm-boersenverlag.svg', alt: 'Logo TM Börsenverlag AG' } }),
                    ]),
                    div('.col-md-3 col-sm-3', [
                        button('.close', '\u2715'),
                    ]),
                ]),
                div('.row', [
                    div('.col-md-12 col-sm-12', [
                        deniedMessage,
                        form('.formBoerse', [
                            input('.inputGroupLogin usernamebv', { attrs: { type: 'text', placeholder: 'Benutzername / E-Mail Adresse', maxlength: 70 } }),
                            br(),
                            input('.inputGroupLogin passwordbv', { attrs: { type: 'password', placeholder: 'Passwort' } }),
                            br(),
                            input('.submitBv okButton bvButton', { attrs: { type: 'button', value: "Einloggen" } }),
                        ]),
                    ]),
                ]),
                div('.loginLoading', { attrs: { style: 'display: none;' } }, [
                    div('.dot-spin loadingBv'),
                ]),
            ]),
        ]),
    ]);
    // Login-meinBoerse
    var view4 = div('.row.overlayWrapper', [
        div('.formular col-md-12', [
            div('.loginBoerseFormular', [
                div('.row', [
                    div('.col-md-3 col-sm-3 zurueck', [
                        button('.return boerseButton', 'zurück'),
                    ]),
                    div('.col-md-6 col-sm-6', [
                        img('.mybacLogo', { attrs: { src: 'https://static.boerse.de/images/Logos/myBAC.svg', alt: 'myBAC' } }),
                    ]),
                    div('.col-md-3 col-sm-3', [
                        button('.close', '\u2715'),
                    ]),
                ]),
                div('.row', [
                    div('.col-md-12 col-sm-12', [
                        deniedMessage,
                        form('.formBoerse', [
                            input('.inputGroupLogin usernamebde', { attrs: { type: 'text', placeholder: 'Benutzername / E-Mail Adresse', maxlength: 70 } }),
                            br(),
                            input('.inputGroupLogin passwordbde', { attrs: { type: 'password', placeholder: 'Passwort' } }),
                            br(),
                            input('.submitBoerse okButton boerseButton', { attrs: { type: 'button', value: "Einloggen" } }),
                        ]),
                    ]),
                ]),
                div('.loginLoading', { attrs: { style: 'display: none;' } }, [
                    div('.dot-spin loadingBoerse'),
                ]),
                div('.row', [
                    div('.col-md-6 col-sm-6', [
                        p('.passwortVergessen', [
                            a('.passwortVergessen', { attrs: { href: 'https://mein.boerse.de/' } }, 'Passwort vergessen?')
                        ]),
                    ]),
                    div('.col-md-6 col-sm-6', [
                        p('.registrieren', [
                            a('.registrierenLink', { attrs: { href: 'https://mein.boerse.de/' } }, 'Jetzt registrieren'),
                        ])
                    ])
                ])
            ]),
        ]),
    ]);
    // Login-IC
    var view5 = div('.row.overlayWrapper', [
        div('.formular.col-md-12', [
            div('.loginIcFormular', [
                div('.row', [
                    div('.col-md-3 col-sm-3 zurueck', [
                        button('.return icButton', 'zurück'),
                    ]),
                    div('.col-md-6 col-sm-6', [
                        img('.mybacLogo', { attrs: { src: 'https://www.boersenverlag.de/fileadmin/image/_logos/Investoren-Club_Logo_Neu-Button.png', alt: 'boerse.de Investoren-Club' } }),
                    ]),
                    div('.col-md-3 col-sm-3', [
                        button('.close', '\u2715'),
                    ]),
                ]),
                div('.row', [
                    div('.col-md-12 col-sm-12', [
                        deniedMessage,
                        form('.formBoerse', [
                            input('.inputGroupLogin usernameic', { attrs: { type: 'text', placeholder: 'Benutzername / E-Mail Adresse', maxlength: 70 } }),
                            br(),
                            input('.inputGroupLogin passwordic', { attrs: { type: 'password', placeholder: 'Passwort' } }),
                            br(),
                            input('.submitIc okButton icButton', { attrs: { type: 'button', value: "Einloggen" } }),
                        ]),
                    ]),
                ]),
                div('.loginLoading', { attrs: { style: 'display: none;' } }, [
                    div('.dot-spin loadingBv'),
                ]),
            ]),
        ]),
    ]);
    // Login-Bereich-wählen
    var loginFrage = div('.row.overlayWrapper', [
        div('.formular', [
            div('.loginBoerseFormular', [
                div('.row', [
                    div('.header-spacer'),
                    button('.close', '\u2715')
                ]),
                p('.loginFrage', 'Bitte wählen Sie Ihren Login-Bereich'),
                div('.login-group', [
                    // login area myBAC
                    div('.login-item', [
                        img('.logoAuswahl boerseForm', {
                            attrs: {
                                src: 'https://static.boerse.de/images/Logos/myBAC_ohneFinanzportal.svg',
                                alt: 'myBAC',
                            },
                        }),
                        p('.boerseFormInfo', 'Ihr persönliches Finanzportal'),
                        button('.boerseButton', [
                            a({ attrs: { href: 'https://mein.boerse.de/' } }, 'Info')
                        ]),
                    ]),
                    // login area BV
                    div('.login-item', [
                        img('.logoAuswahl bvForm', {
                            attrs: {
                                src: 'https://www.boersenverlag.de/fileadmin/img/Logos/tm-boersenverlag.svg',
                                alt: 'Logo TM Börsenverlag AG',
                            },
                        }),
                        p('.bvFormInfo', 'Ihr Abobereich'),
                        button('.bvButton', [
                            a({ attrs: { href: 'https://www.boersenverlag.de/' } }, 'Info')
                        ]),
                    ]),
                    // login area IC
                    div('.login-item', [
                        img('.logoAuswahl icForm', {
                            attrs: {
                                src: 'https://www.boersenverlag.de/fileadmin/image/_logos/Investoren-Club_Logo_Neu-Button.png',
                                alt: 'boerse.de Investoren-Club',
                            },
                        }),
                        p('.icFormInfo', 'Ihr Investorenbereich'),
                        button('.icButton', [
                            a({ attrs: { href: 'https://www.boerse-investoren-club.de/' } }, 'Info')
                        ]),
                    ]),
                    // login area myChampions100
                    div('.login-item', [
                        a('.myChampions100Link', { attrs: { href: 'https://app.mychampions.de/login' } }, [
                            img('.logoAuswahlChampions100 myChampionsForm', {
                                attrs: {
                                    src: 'https://www.mychampions100.de/fileadmin/img/Logo_myChampions100_rgb_ohne_neu.svg',
                                    alt: 'myChampions100',
                                },
                            }),
                        ]),
                        p('.myChampionsFormInfo', 'Der geniale Direktanlageservice'),
                        button('.myChampionsButton', [
                            a({ attrs: { href: 'https://www.mychampions100.de/' } }, 'Info')
                        ]),
                    ]),
                ]),
            ]),
        ]),
    ]);
    // Weiterleiten Message
    var view3 = div('.row.overlayWrapper', [
        div('.formular.col-md-12', [
            div('.loginBoerseFormular', [
                div('.acceptContainer', [
                    div('.success-checkmark', [
                        div('.check-icon', [
                            span('.icon-line line-tip'),
                            span('.icon-line line-long'),
                            div('.icon-circle'),
                            div('.icon-fix'),
                        ]),
                    ]),
                    p('.acceptMessage', 'Sie werden weitergeleitet...')
                ]),
            ]),
        ]),
    ]);
    // Login-Button und d-none Login-Bereich 
    var view = div([
        a('#naviLoginLinkDesktop', 'Login'),
        div(state.getStateOverlay() + ' background'),
        div('.row justify-content-md-center', [
            div('.col-md-6 col-sm-12 col-xs-12', [
                div(state.getStateOverlay() + ' overlayLogin', [
                    loginFrage
                ])
            ]),
        ]),
    ]);
    if (state.getStateLoginForm() === 'boerseForm') {
        var view = div([
            a('#naviLoginLinkDesktop', 'Login'),
            div(state.getStateOverlay() + ' background'),
            div('.row justify-content-md-center', [
                div('.col-md-6 col-sm-12 col-xs-12', [
                    div(state.getStateOverlay() + ' overlayLogin', [
                        view4
                    ])
                ]),
            ]),
        ]);
    }
    else if (state.getStateLoginForm() === 'bvForm') {
        var view = div([
            a('#naviLoginLinkDesktop', 'Login'),
            div(state.getStateOverlay() + ' background'),
            div('.row justify-content-md-center', [
                div('.col-md-6 col-sm-12 col-xs-12', [
                    div(state.getStateOverlay() + ' overlayLogin', [
                        view2
                    ])
                ]),
            ]),
        ]);
    }
    else if (state.getStateLoginForm() === 'icForm') {
        var view = div([
            a('#naviLoginLinkDesktop', 'Login'),
            div(state.getStateOverlay() + ' background'),
            div('.row justify-content-md-center', [
                div('.col-md-6 col-sm-12 col-xs-12', [
                    div(state.getStateOverlay() + ' overlayLogin', [
                        view5
                    ])
                ]),
            ]),
        ]);
    }
    else if (state.getStateLoginForm() === 'accept') {
        var view = div([
            a('#naviLoginLinkDesktop', 'Login'),
            div(state.getStateOverlay() + ' background'),
            div('.row justify-content-md-center', [
                div('.col-md-6 col-sm-12 col-xs-12', [
                    div(state.getStateOverlay() + ' overlayLogin', [
                        view3
                    ])
                ]),
            ]),
        ]);
    }
    return view;
}
function toViewMobile(state) {
    if (state.getStateLogin() === false) {
        var deniedMessage = div([p('.loginFailed', 'Username oder Passwort sind nicht korrekt.')]);
    }
    else {
        var deniedMessage = div([p('.loginFailed')]);
    }
    // Login-BV-Mobile
    var view2 = div('.row', [
        div('.formularMobile col-sm-12 col-xs-12', [
            div('.loginBoerseFormularMobile', [
                div('.row', [
                    div('.col-sm-3 col-xs-3 zurueck', [
                        button('.returnMobile bvButton', 'zurück'),
                    ]),
                    div('.col-sm-6 col-xs-6'),
                    div('.col-sm-3 col-xs-3', [
                        button('.closeMobile', '\u2715'),
                    ]),
                ]),
                div('.row', [
                    div('.col-sm-12 col-xs-12', [
                        img('.mybacLogoMobile', { attrs: { src: 'https://www.boerse.de/fileadmin/img/Logos/tm-boersenverlag.svg', alt: 'Logo TM Börsenverlag AG' } }),
                    ]),
                ]),
                div('.row', [
                    div('.col-sm-12 col-xs-12', [
                        deniedMessage,
                        form('.formBoerse', [
                            input('.inputGroupLogin usernamebv', { attrs: { type: 'text', placeholder: 'Benutzername / E-Mail Adresse', maxlength: 70 } }),
                            br(),
                            input('.inputGroupLogin passwordbv', { attrs: { type: 'password', placeholder: 'Passwort' } }),
                            br(),
                            input('.submitBv okButton bvButton', { attrs: { type: 'button', value: "Einloggen" } }),
                        ]),
                    ]),
                ]),
                div('.loginLoadingMobile', { attrs: { style: 'display: none;' } }, [
                    div('.dot-spin loadingBv'),
                ]),
            ]),
        ]),
    ]);
    // Login-meinBoerse-Mobile
    var view4 = div('.row', [
        div('.formularMobile col-sm-12 col-xs-12', [
            div('.loginBoerseFormularMobile', [
                div('.row', [
                    div('.col-sm-3 col-xs-3 zurueck', [
                        button('.returnMobile boerseButton', 'zurück'),
                    ]),
                    div('.col-sm-6 col-xs-6'),
                    div('.col-sm-3 col-xs-3', [
                        button('.closeMobile', '\u2715'),
                    ]),
                ]),
                div('.row', [
                    div('.col-sm-12 col-xs-12', [
                        img('.mybacLogoMobile', { attrs: { src: 'https://static.boerse.de/images/Logos/myBAC.svg', alt: 'myBAC' } }),
                    ]),
                ]),
                div('.row', [
                    div('.col-sm-12 col-xs-12', [
                        deniedMessage,
                        form('.formBoerse', [
                            input('.inputGroupLogin usernamebde', { attrs: { type: 'text', placeholder: 'Benutzername / E-Mail Adresse', maxlength: 70 } }),
                            br(),
                            input('.inputGroupLogin passwordbde', { attrs: { type: 'password', placeholder: 'Passwort' } }),
                            br(),
                            input('.submitBoerse okButton boerseButton', { attrs: { type: 'button', value: "Einloggen" } }),
                        ]),
                    ]),
                ]),
                div('.loginLoadingMobile', { attrs: { style: 'display: none;' } }, [
                    div('.dot-spin loadingBoerse'),
                ]),
                div('.row', [
                    div('.col-sm-6 col-xs-6', [
                        p('.passwortVergessen', [
                            a('.passwortVergessen', { attrs: { href: 'https://mein.boerse.de/' } }, 'Passwort vergessen?')
                        ]),
                    ]),
                    div('.col-sm-6 col-xs-6', [
                        p('.registrieren', [
                            a('.registrierenLink', { attrs: { href: 'https://mein.boerse.de/' } }, 'Jetzt registrieren'),
                        ])
                    ])
                ])
            ]),
        ]),
    ]);
    // Login-IC-Mobile
    var view5 = div('.row', [
        div('.formularMobile col-sm-12 col-xs-12', [
            div('.loginBoerseFormularMobile', [
                div('.row', [
                    div('.col-sm-3 col-xs-3 zurueck', [
                        button('.returnMobile icButton', 'zurück'),
                    ]),
                    div('.col-sm-6 col-xs-6'),
                    div('.col-sm-3 col-xs-3', [
                        button('.closeMobile', '\u2715'),
                    ]),
                ]),
                div('.row', [
                    div('.col-sm-12 col-xs-12', [
                        img('.mybacLogoMobile', { attrs: { src: 'https://www.boersenverlag.de/fileadmin/image/_logos/Investoren-Club_Logo_Neu-Button.png', alt: 'boerse.de Investoren-Club' } }),
                    ]),
                ]),
                div('.row', [
                    div('.col-sm-12 col-xs-12', [
                        deniedMessage,
                        form('.formBoerse', [
                            input('.inputGroupLogin usernameic', { attrs: { type: 'text', placeholder: 'Benutzername / E-Mail Adresse', maxlength: 70 } }),
                            br(),
                            input('.inputGroupLogin passwordic', { attrs: { type: 'password', placeholder: 'Passwort' } }),
                            br(),
                            input('.submitIc okButton icButton', { attrs: { type: 'button', value: "Einloggen" } }),
                        ]),
                    ]),
                ]),
                div('.loginLoadingMobile', { attrs: { style: 'display: none;' } }, [
                    div('.dot-spin loadingBv'),
                ]),
            ]),
        ]),
    ]);
    // Login-Bereich-wählen-Mobile
    var loginFrage = div('.row', [
        div('.formularMobile', [
            div('.loginBoerseFormularMobile', [
                div('.row', [
                    div('.loginFrageMobile', ''),
                    div('.closeButtonWrapperMobile', [button('.closeMobile', '\u2715')]),
                ]),
                // Login areas mobile
                div('.login-group-mobile', [
                    // login area myBAC
                    div('.login-item-mobile', [
                        img('.logoAuswahl auswahlMobileImg boerseFormMobile', {
                            attrs: {
                                src: 'https://static.boerse.de/images/Logos/myBAC_ohneFinanzportal.svg',
                                alt: 'myBAC',
                            },
                        }),
                        p('.boerseFormInfo', 'Ihr persönliches Finanzportal'),
                        button('.boerseButton', [
                            a({ attrs: { href: 'https://mein.boerse.de/' } }, 'Info'),
                        ]),
                    ]),
                    // login area BV
                    div('.login-item-mobile', [
                        img('.logoAuswahl auswahlMobileImg bvFormMobile', {
                            attrs: {
                                src: 'https://www.boersenverlag.de/fileadmin/img/Logos/tm-boersenverlag.svg',
                                alt: 'Logo TM Börsenverlag AG',
                            },
                        }),
                        p('.bvFormInfo', 'Ihr Abobereich'),
                        button('.bvButton', [
                            a({ attrs: { href: 'https://www.boersenverlag.de/' } }, 'Info'),
                        ]),
                    ]),
                    // login area IC
                    div('.login-item-mobile', [
                        img('.logoAuswahl auswahlMobileImg icFormMobile', {
                            attrs: {
                                src: 'https://www.boersenverlag.de/fileadmin/image/_logos/Investoren-Club_Logo_Neu-Button.png',
                                alt: 'boerse.de Investoren-Club',
                            },
                        }),
                        p('.icFormInfo', 'Ihr Investorenbereich'),
                        button('.icButton', [
                            a({ attrs: { href: 'https://www.boerse-investoren-club.de/' } }, 'Info'),
                        ]),
                    ]),
                    // login area myChampions100
                    div('.login-item-mobile', [
                        a('.myChampions100LinkMobile', { attrs: { href: 'https://app.mychampions.de/login' } }, [
                            img('.logoAuswahlChampions100 auswahlMobileImg myChampionsFormMobile', {
                                attrs: {
                                    src: 'https://www.mychampions100.de/fileadmin/img/Logo_myChampions100_rgb_ohne_neu.svg',
                                    alt: 'myChampions100',
                                },
                            }),
                        ]),
                        p('.myChampionsFormInfo', 'Der geniale Direktanlageservice'),
                        button('.myChampionsButton', [
                            a({ attrs: { href: 'https://www.mychampions100.de/' } }, 'Info'),
                        ]),
                    ]),
                ]),
            ]),
        ]),
    ]);
    // Weiterleiten Message Mobile
    var view3 = div('.row', [
        div('.formularMobile.col-md-12', [
            div('.loginBoerseFormularMobile', [
                div('.acceptContainerMobile', [
                    div('.success-checkmark', [
                        div('.check-icon', [
                            span('.icon-line line-tip'),
                            span('.icon-line line-long'),
                            div('.icon-circle'),
                            div('.icon-fix'),
                        ]),
                    ]),
                    p('.acceptMessage', 'Sie werden weitergeleitet...')
                ]),
            ]),
        ]),
    ]);
    // Login-Button und d-none Login-Bereich Mobile
    var view = div([
        div('.tdNaviGroupLogin', 'Login'),
        // div(state.getStateOverlay() + ' background'),
        div('.row justify-content-md-center', [
            div('.col-12', [
                div(state.getStateOverlay() + ' overlayLoginMobile', [
                    loginFrage
                ])
            ]),
        ]),
    ]);
    if (state.getStateLoginForm() === 'boerseForm') {
        var view = div([
            div('.tdNaviGroupLogin', 'Login'),
            // div(state.getStateOverlay() + ' background'),
            div('.row justify-content-md-center', [
                div('.col-md-6 col-sm-12 col-xs-12', [
                    div(state.getStateOverlay() + ' overlayLogin', [
                        view4
                    ])
                ]),
            ]),
        ]);
    }
    else if (state.getStateLoginForm() === 'bvForm') {
        var view = div([
            div('.tdNaviGroupLogin', 'Login'),
            // div(state.getStateOverlay() + ' background'),
            div('.row justify-content-md-center', [
                div('.col-md-6 col-sm-12 col-xs-12 col-xs-12', [
                    div(state.getStateOverlay() + ' overlayLogin', [
                        view2
                    ])
                ]),
            ]),
        ]);
    }
    else if (state.getStateLoginForm() === 'icForm') {
        var view = div([
            div('.tdNaviGroupLogin', 'Login'),
            // div(state.getStateOverlay() + ' background'),
            div('.row justify-content-md-center', [
                div('.col-md-6 col-sm-12 col-xs-12 col-xs-12', [
                    div(state.getStateOverlay() + ' overlayLogin', [
                        view5
                    ])
                ]),
            ]),
        ]);
    }
    else if (state.getStateLoginForm() === 'accept') {
        var view = div([
            div(state.getStateOverlay() + ' background'),
            div('.row justify-content-md-center', [
                div('.col-md-6 col-sm-12 col-xs-12', [
                    div(state.getStateOverlay() + ' overlayLogin', [
                        view3
                    ])
                ]),
            ]),
        ]);
    }
    return view;
}
var LoginRequest = /** @class */ (function () {
    function LoginRequest(user, pass) {
        this.user = user;
        this.pass = pass;
        this.userEncoded = encrypt(user);
        this.passEncoded = encrypt(pass);
    }
    LoginRequest.fromArray = function (args) {
        return new LoginRequest(args[0], args[1]);
    };
    LoginRequest.prototype.getUser = function () {
        return this.user;
    };
    LoginRequest.prototype.getPass = function () {
        return this.pass;
    };
    LoginRequest.prototype.getUserEncoded = function () {
        return this.userEncoded;
    };
    LoginRequest.prototype.getPassEncoded = function () {
        return this.passEncoded;
    };
    return LoginRequest;
}());
function main(sources) {
    var streamActions = xs.merge(xs.of(new StartAction()), sources.DOM.select('#naviLoginLinkDesktop').events('click').mapTo(new OverlayAction('.visible')), sources.DOM.select('.close').events('click').mapTo(new OverlayAction('.hidden')), sources.DOM.select('.background').events('click').mapTo(new OverlayAction('.hidden')), sources.DOM.select('.bvForm').events('click').mapTo(new LoginAction('bvForm')), sources.DOM.select('.bvFormInfo').events('click').mapTo(new LoginAction('bvForm')), sources.DOM.select('.icForm').events('click').mapTo(new LoginAction('icForm')), sources.DOM.select('.icFormInfo').events('click').mapTo(new LoginAction('icForm')), sources.DOM.select('.boerseForm').events('click').mapTo(new LoginAction('boerseForm')), sources.DOM.select('.boerseFormInfo').events('click').mapTo(new LoginAction('boerseForm')), sources.DOM.select('.myChampionsForm').events('click').mapTo(new LoginAction('myChampionsForm')), sources.DOM.select('.myChampionsFormInfo').events('click').mapTo(new LoginAction('myChampionsForm')), sources.DOM.select('.return').events('click').mapTo(new LoginAction('return')), sources.DOM.select('.usernamebde').events('keydown').map(onEnterBde).flatten(), sources.DOM.select('.passwordbde').events('keydown').map(onEnterBde).flatten(), sources.DOM.select('.submitBoerse').events('click').map(requestBde).flatten(), sources.DOM.select('.usernamebv').events('keydown').map(onEnterBv).flatten(), sources.DOM.select('.passwordbv').events('keydown').map(onEnterBv).flatten(), sources.DOM.select('.submitBv').events('click').map(requestBv).flatten(), sources.DOM.select('.usernameic').events('keydown').map(onEnterIc).flatten(), sources.DOM.select('.passwordic').events('keydown').map(onEnterIc).flatten(), sources.DOM.select('.submitIc').events('click').map(requestIc).flatten(), sources.DOM.select('.registrierenLink').events('click').mapTo(new RegisterAction('true')));
    var streamVNodes = streamActions
        .fold(function (acc, it) { return update(acc, it); }, new PageState('.hidden'))
        .map(toView);
    return {
        DOM: streamVNodes
    };
}
function mainMobile(sources) {
    var streamActions = xs.merge(xs.of(new StartAction()), sources.DOM.select('.tdNaviGroupLogin').events('click').mapTo(new OverlayAction('.visible')), sources.DOM.select('.closeMobile').events('click').mapTo(new OverlayAction('.hidden')), sources.DOM.select('.bvFormMobile').events('click').mapTo(new LoginAction('bvForm')), sources.DOM.select('.bvFormInfo').events('click').mapTo(new LoginAction('bvForm')), sources.DOM.select('.icFormMobile').events('click').mapTo(new LoginAction('icForm')), sources.DOM.select('.icFormInfo').events('click').mapTo(new LoginAction('icForm')), sources.DOM.select('.boerseFormMobile').events('click').mapTo(new LoginAction('boerseForm')), sources.DOM.select('.boerseFormInfo').events('click').mapTo(new LoginAction('boerseForm')), sources.DOM.select('.myChampionsFormMobile').events('click').mapTo(new LoginAction('myChampionsForm')), sources.DOM.select('.myChampionsFormInfo').events('click').mapTo(new LoginAction('myChampionsForm')), sources.DOM.select('.returnMobile').events('click').mapTo(new LoginAction('return')), sources.DOM.select('.usernamebde').events('keydown').map(onEnterBde).flatten(), sources.DOM.select('.passwordbde').events('keydown').map(onEnterBde).flatten(), sources.DOM.select('.submitBoerse').events('click').map(requestBde).flatten(), sources.DOM.select('.usernamebv').events('keydown').map(onEnterBv).flatten(), sources.DOM.select('.passwordbv').events('keydown').map(onEnterBv).flatten(), sources.DOM.select('.submitBv').events('click').map(requestBv).flatten(), sources.DOM.select('.usernameic').events('keydown').map(onEnterIc).flatten(), sources.DOM.select('.passwordic').events('keydown').map(onEnterIc).flatten(), sources.DOM.select('.submitIc').events('click').map(requestIc).flatten(), sources.DOM.select('.registrierenLink').events('click').mapTo(new RegisterAction('true')));
    var streamVNodes = streamActions
        .fold(function (acc, it) { return update(acc, it); }, new PageState('.hidden'))
        .map(toViewMobile);
    return {
        DOM: streamVNodes
    };
}
function mapLog(val) {
    console.log(val);
    return val;
}
function onEnterBv(ev) {
    if (ev.which == 13) {
        return requestBv();
    }
    else {
        return xs.of(new DoNothingAction());
    }
}
function onEnterIc(ev) {
    if (ev.which == 13) {
        return requestIc();
    }
    else {
        return xs.of(new DoNothingAction());
    }
}
function onEnterBde(ev) {
    if (ev.which == 13) {
        return requestBde();
    }
    else {
        return xs.of(new DoNothingAction());
    }
}
function requestBde() {
    showLoadingWithoutStream();
    return xs.combine(xs.of(document.querySelector(".usernamebde").value), xs.of(document.querySelector(".passwordbde").value)).map(LoginRequest.fromArray)
        .map(function (lr) {
        return Stream.fromPromise(
        // Von BV nach boerse
        // fetch('https://www.boersenverlag.de/fileadmin/scripts/auth.php', {
        // Von boerse nach BV
        fetch('https://www.boerse.de/auth/auth.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username: lr.getUserEncoded(), password: lr.getPassEncoded(), token: encrypt(Timestamp.timestamp.toString()), destination: 'boerse' }),
        })
            .then(logJson)
            .then(function (json) { return new ResponseAction(json, lr); }));
    }).flatten().compose(delay(500));
}
function requestBv() {
    showLoadingWithoutStream();
    return xs.combine(xs.of(document.querySelector(".usernamebv").value), xs.of(document.querySelector(".passwordbv").value)).map(LoginRequest.fromArray)
        .map(function (lr) {
        return Stream.fromPromise(
        // Von BV nach boerse
        // fetch('https://www.boersenverlag.de/fileadmin/scripts/auth.php', {
        // Von boerse nach BV
        fetch('https://www.boerse.de/auth/auth.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username: lr.getUserEncoded(), password: lr.getPassEncoded(), token: encrypt(Timestamp.timestamp.toString()), destination: 'bv' }),
        })
            .then(function (response) { return response.json(); })
            .then(function (json) { return new ResponseAction(json, lr); }));
    }).flatten().compose(delay(500));
}
function requestIc() {
    showLoadingWithoutStream();
    return xs.combine(xs.of(document.querySelector(".usernameic").value), xs.of(document.querySelector(".passwordic").value)).map(LoginRequest.fromArray)
        .map(function (lr) {
        return Stream.fromPromise(
        // Von BV nach boerse
        // fetch('https://www.boersenverlag.de/fileadmin/scripts/auth.php', {
        // Von boerse nach BV
        fetch('https://www.boerse.de/auth/auth.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username: lr.getUserEncoded(), password: lr.getPassEncoded(), token: encrypt(Timestamp.timestamp.toString()), destination: 'ic' }),
        })
            .then(function (response) { return response.json(); })
            .then(function (json) { return new ResponseAction(json, lr); }));
    }).flatten().compose(delay(500));
}
function logJson(response) {
    // console.log(response)
    var ret = response.json();
    // console.log(ret)
    return ret;
}
function showLoadingWithoutStream() {
    $('.loginLoadingMobile').show();
    $('.loginLoading').show();
    $('.okButton').addClass('buttonGrey');
}
function hideLoading() {
    $('.loginLoadingMobile').hide();
    $('.loginLoading').hide();
    $('.okButton').removeClass('buttonGrey');
}
var count = 0;
run(main, {
    DOM: makeDOMDriver('#app')
});
run(mainMobile, {
    DOM: makeDOMDriver('#app_mobile')
});
